<template>
  <el-dialog
    class="support-dialog"
    title="Article Submission"
    :visible.sync="visible"
    width="60%"
  >
    <div class="support-dialog-content">
      <p class="label">
        Before submitting your article to Global Youth Pulses (GYP), please read
        and agree to the following statements by checking the box:
      </p>
      <p class="label">
        <span class="bold">1. Originality Statement:</span> We affirm that we
        are the original authors of this article, and the content is our
        independent creation, not infringing upon any third-party intellectual
        property rights.
      </p>
      <p class="label">
        <span class="bold">2. Copyright Responsibility:</span> We understand and
        agree to be fully responsible for any copyright issues related to the
        content of our article. I am willing to bear all legal responsibilities
        arising from any infringement of others' legitimate rights and
        interests.
      </p>
      <p class="label">
        <span class="bold">3. Publication Authorization:</span> We grant GYP the
        right to edit, publish, and disseminate our article. We acknowledge that
        GYP does not offer monetary compensation for published articles.
      </p>
      <p class="label">
        <span class="bold">4. Revocation of Awards and Publication:</span> GYP
        reserves the right to revoke any awards and the publication of the
        article if it is found to violate the platform's rules or legal
        standards.
      </p>
      <div class="agree">
        <div>
          <i
            v-if="!agreeShow"
            class="el-icon-success"
            style="color: #eb7269"
          ></i>
          I have read and agree to the terms stated above.
        </div>
        <el-button v-if="agreeShow" type="primary" @click="handleAgreeClick"
          >Agree</el-button
        >
      </div>
    </div>

    <table
      width="100%"
      border="1"
      align="center"
      cellspacing="0"
      cellpadding="0"
    >
      <tr>
        <th width="25%" v-for="(item, index) in fillArray" :key="index">
          {{
            item.student
              ? `${item.student.student_givenName_pinyin} ${item.student.student_lastName_pinyin}`
              : item
          }}
        </th>
      </tr>
      <tr>
        <td v-for="(item, index) in fillArray" :key="index">
          {{
            hasAgree(item.student_id)
              ? "Agreed"
              : item.student
              ? "Pending"
              : "-"
          }}
        </td>
      </tr>
    </table>

    <span slot="footer" class="support-dialog-footer">
      <el-button type="primary" :disabled="disabled" @click="handleSubmit"
        >Submit</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { agreeArticleStatements } from "../api/index";
import { getUserId } from "../utils/store";
export default {
  name: "supportModal",

  props: {
    value: {
      type: Boolean,
    },
    authorList: {
      type: Array,
      default: [],
    },
    id: {
      type: String,
      default: null,
    },
    agreeData: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {
      visible: this.value,
      checked: false,
      // agreeShow: true,
      userId: getUserId(),
    };
  },

  computed: {
    fillArray() {
      return this.authorList.length < 3
        ? [
            ...this.authorList,
            ...new Array(4 - this.authorList.length).fill("-"),
          ]
        : this.authorList;
    },
    agreeShow() {
      return !this.hasAgree(this.userId);
    },
    disabled() {
      return Object.keys(this.agreeData).length !== this.authorList.length;
    },
  },
  watch: {
    value(n, o) {
      this.visible = n;
    },
    visible(n, o) {
      this.$emit("input", n);
    },
  },

  mounted() {},

  methods: {
    hasAgree(id) {
      return Object.keys(this.agreeData).includes(id);
    },

    async handleAgreeClick() {
      const res = await agreeArticleStatements(getUserId(), this.id);
      const { code } = res.data;
      if (code === 0) {
        this.$message.success("Agreed the terms.");
        this.agreeShow = false;
      }
    },
    handleSubmit() {
      this.$emit("submit");
    },
  },
};
</script>

<style lang="scss" scoped>
.support-dialog {
  /deep/.el-dialog__header {
    text-align: center;
    .el-dialog__title {
      color: #eb7269;
      font-family: GravityBold;
    }
  }
  /deep/.el-dialog__footer {
    text-align: center;
  }
  &-content {
    margin-bottom: 20px;
    .label {
      padding-bottom: 20px;
    }
    .bold {
      font-weight: 600;
    }
    .agree {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
table {
  text-align: center;
}
table th {
  color: #eb7269;
  background: #fdf2f0;
  padding: 12px 0px;
}
table td {
  border: 1px solid #fdf2f0;
  border-top: none;
  padding: 10px 0px;
}
</style>
