<template>
  <div>
    <editor :data="data" />
  </div>
</template>

<script>
import editor from "@/components/editor";
import { getArticle } from "@/api";
export default {
  components: {
    editor,
  },
  data() {
    return {
      data: {},
    };
  },
  methods: {
    async queryEditArticle(id) {
      const { data } = await getArticle(id);
      if (data.code === 0) {
        this.data = data.data;
      } else {
        this.$message.error(data.msg);
      }
    },
  },
  async mounted() {
    console.log(this.$route.query);
    const id = this.$route.query.id;
    if (!id) return;
    await this.queryEditArticle(id);
  }
};
</script>

<style lang="scss" scoped></style>
