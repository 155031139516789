<template>
  <el-dialog
    class="author-dialog"
    title="Co-author Management"
    :visible.sync="visible"
    width="50%"
  >
    <div class="author-dialog-content">
      <p class="label">
        GYP promotes co-authorship, with articles requiring a team of 2 or 4
        collaborators.
      </p>
      <div class="invite">
        <div class="title">Invite a Co-author</div>
        <div class="invite-con">
          <el-input
            class="email"
            type="email"
            v-model="email"
            autocomplete="off"
            placeholder="Please input the email"
          ></el-input>
          <el-button type="primary" class="btn" @click="handleAddMember"
            >Invite</el-button
          >
        </div>
      </div>
      <table
        width="500px"
        border="0"
        align="center"
        cellspacing="0"
        cellpadding="0"
      >
        <tr>
          <th width="30%">Co-author</th>
          <th width="40%">Email</th>
          <th width="30%">Status | Operation</th>
        </tr>
        <tr v-for="(item, index) in list" :key="index">
          <td>
            {{ item.student.student_givenName_pinyin }}
            {{ item.student.student_lastName_pinyin }}
          </td>
          <td>
            {{ item.student_email }}
          </td>
          <td class="operate">
            <span>{{
              item.member_status === "joined" ? "Joined" : "Invited"
            }}</span>
            <el-button
              v-if="item.member_status !== 'joined'"
              @click="handleDelete(item._id.$id)"
              type="text"
              size="small"
              class="del-btn"
              >Delete</el-button
            >
          </td>
        </tr>
      </table>
    </div>

    <span slot="footer" class="author-dialog-footer">
      <el-button type="primary" class="btn" @click="handleSubmit"
        >Submit</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { addArticleMember, removeArticleMember } from "../api/index";
import { getUserId } from "../utils/store";
export default {
  name: "authorModal",

  props: {
    value: {
      type: Boolean,
    },
    list: {
      type: Array,
      default: [],
    },
    id: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      visible: this.value,
      checked: false,
      email: "",
      autorData: [
        {
          name: "xxxx",
          email: "11111",
          status: 1,
        },
        {
          name: "xxxx",
          email: "11111",
          status: 0,
        },
      ],
    };
  },

  watch: {
    value(n, o) {
      this.visible = n;
    },
    visible(n, o) {
      this.$emit("input", n);
    },
  },

  mounted() {},

  methods: {
    handleSubmit() {
      this.visible = false;
    },
    async handleAddMember() {
      const regEmail =
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
      if (!regEmail.test(this.email)) {
        this.$message.error("Please input correct email");
        return;
      }
      const res = await addArticleMember(this.id, this.email, getUserId());
      const { code, msg } = res.data || {};
      if (code === 0) {
        this.$message.success("Invitation sent successfully.");
        this.$emit("refreshMember");
      } else {
        this.$message.error(msg);
      }
    },
    async handleDelete(id) {
      const res = await removeArticleMember(id);
      const { code, msg } = res.data || {};
      if (code === 0) {
        this.$message.success("Member Delete successfully.");
        this.$emit("refreshMember");
      } else {
        this.$message.error(msg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.author-dialog {
  /deep/.el-dialog__header {
    text-align: center;
    .el-dialog__title {
      color: #eb7269;
      font-family: GravityBold;
    }
  }
  /deep/.el-dialog__footer {
    text-align: center;
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    .invite {
      font-size: 14px;
      width: 100%;
      margin-bottom: 40px;
      .title {
        color: #eb7269;
        margin-bottom: 20px;
      }
      &-con {
        display: flex;
        justify-content: space-between;
        .email {
          width: 300px;
        }
      }
    }
    table {
      text-align: left;
      width: 100%;
    }
    table th {
      color: #eb7269;
      // background: #fdf2f0;
      padding: 12px 0px;
      border-bottom: 1px solid #333333;
      font-weight: 400;
    }
    table td {
      // border: 1px solid #fdf2f0;
      border-top: none;
      padding: 8px 0px;
      span {
        display: block;
      }
    }
    .label {
      padding-bottom: 20px;
      word-break: break-word;
    }
  }
  .btn {
    width: 120px;
  }
  .operate {
    display: flex;
    align-items: center;
    .del-btn {
      position: relative;
      margin-left: 4px;
      &::before {
        content: "";
        display: inline-block;
        width: 1px;
        height: 10px;
        background: #333333;
        margin-right: 4px;
      }
    }
  }
}
</style>
