import Quill from "quill";
let Parchment = Quill.import("parchment");
let lingHeight = ['initial', '1', '1.5', '1.75', '2', '3', '4'];
class lineHeightAttributor extends Parchment.Attributor.Style {}
const lineHeightStyle = new lineHeightAttributor("lineHeight", "line-height", {
  scope: Parchment.Scope.INLINE,
  whitelist: lingHeight
});
export { lineHeightStyle };



