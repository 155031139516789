<template>
  <el-dialog
    title="图片剪裁"
    :visible.sync="dialogVisible"
    class="crop-dialog"
    append-to-body
  >
    <div style="padding: 0 20px">
      <div
        :style="{
          textAlign: 'center',
          width: cropperWidth != 0 ? cropperWidth + 'px' : 'auto',
          height: cropperHeight + 'px',
        }"
      >
        <VueCropper
          ref="cropper"
          :img="cropperImg"
          :output-size="outputSize"
          :output-type="outputType"
          :info="info"
          :full="full"
          :can-move="canMove"
          :can-move-box="canMoveBox"
          :original="original"
          :auto-crop="autoCrop"
          :can-scale="canScale"
          :fixed="fixed"
          :fixed-number="fixedNumber"
          :fixed-box="fixedBox"
          :center-box="centerBox"
          :info-true="infoTrue"
          :auto-crop-width="autoCropWidth"
          :auto-crop-height="autoCropHeight"
        />
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="finish">
        确认
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { VueCropper } from "vue-cropper";
export default {
  name: "Cropper",
  components: {
    VueCropper,
  },
  props: {
    value: {
      type: Boolean,
    },
    cropperImg: {
      type: String,
      default: "",
    },
    marginTop: {
      type: Number,
      default: 0,
    },
    // 上传属性

    // 图片路径
    imgSrc: {
      type: String,
      default: "",
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 列表索引
    listIndex: {
      type: Number,
      default: null,
    },
    // 上传路径
    actionUrl: {
      type: String,
      default: "#",
    },
    // 上传宽度
    uploadWidth: {
      type: Number,
      default: 100,
    },
    // 上传高度
    uploadHeight: {
      type: Number,
      default: 100,
    },
    // 图片显示角度 传值详情参考mdn object-fit
    objectFit: {
      type: String,
      default: "fill",
    },
    // 裁剪属性

    // 裁剪弹出框的宽度
    cropperWidth: {
      type: Number,
      default: 0,
    },
    // 裁剪弹出框的高度
    cropperHeight: {
      type: Number,
      default: 600,
    },
    // 裁剪生成图片的质量 0.1-1
    outputSize: {
      type: Number,
      default: 1,
    },
    // 裁剪生成图片的格式
    outputType: {
      type: String,
      default: "png",
    },
    // 裁剪框的大小信息
    info: {
      type: Boolean,
      default: true,
    },
    // 是否输出原图比例的截图
    full: {
      type: Boolean,
      default: true,
    },
    // 截图框能否拖动
    canMove: {
      type: Boolean,
      default: true,
    },
    // 截图框能否拖动
    canMoveBox: {
      type: Boolean,
      default: true,
    },
    // 上传图片按照原始比例渲染
    original: {
      type: Boolean,
      default: true,
    },
    // 是否默认生成截图框
    autoCrop: {
      type: Boolean,
      default: true,
    },
    // 图片是否允许滚轮缩放
    canScale: {
      type: Boolean,
      default: true,
    },
    // 是否开启截图框宽高固定比例

    fixed: {
      type: Boolean,
      default: true,
    },
    // 截图框的宽高比例 开启fixed生效
    fixedNumber: {
      type: Array,
      default: () => [9, 16],
    },
    // 固定截图框大小 不允许改变
    fixedBox: {
      type: Boolean,
      default: false,
    },
    // 截图框是否被限制在图片里面
    centerBox: {
      type: Boolean,
      default: true,
    },
    // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
    infoTrue: {
      type: Boolean,
      default: true,
    },
    // 默认生成截图框宽度
    autoCropWidth: {
      type: Number,
      default: 270,
    },
    // 默认生成截图框高度
    autoCropHeight: {
      type: Number,
      default: 480,
    },
    // 是否出现操作按钮
    actionButtonFlag: {
      type: Boolean,
      default: false,
    },
    // 裁剪路径输出格式 base64：base64； blob:blob;
    cropFormat: {
      type: String,
      default: "blob",
    },
    // 图片最大宽度
    maxImgWidth: {
      type: Number,
      default: 648,
    },
    // 图片最大高度
    maxImgHeight: {
      type: Number,
      default: 1152,
    },
  },

  data() {
    return {
      visible: this.value,
      cropperImg: "", // 裁剪图片的地址
      loading: false,
    };
  },

  watch: {
    value(n, o) {
      this.visible = n;
    },
    visible(n, o) {
      this.$emit("input", n);
    },
  },

  mounted() {},

  methods: {
    // 放大/缩小
    changeScaleHandle(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },

    clearImgHandle() {
      this.cropperImg = "";
    },
    finish() {
      if (this.cropFormat == "base64") {
        // 获取截图的 base64 数据
        this.$refs.cropper.getCropData((data) => {
          this.loading = true;
          this.dialogVisible = false;

          this.getImgHeight(data, this.maxImgWidth, this.maxImgHeight).then(
            (imgUrl) => {
              // console.log(imgUrl, "base64");
              this.previewImg = imgUrl;
              if (this.listIndex !== null) {
                this.$emit("successCheng", this.previewImg, this.listIndex);
              } else {
                this.$emit("successCheng", this.previewImg);
              }
            }
          );
        });
      } else if (this.cropFormat == "blob") {
        // 获取截图的 blob 数据
        this.$refs.cropper.getCropBlob((blob) => {
          this.loading = true;
          this.dialogVisible = false;

          this.getImgHeight(
            URL.createObjectURL(blob),
            this.maxImgWidth,
            this.maxImgHeight
          ).then((imgUrl) => {
            // console.log(imgUrl, "blob");
            this.previewImg = imgUrl;
            if (this.listIndex !== null) {
              this.$emit("successCheng", this.previewImg, this.listIndex);
            } else {
              this.$emit("successCheng", this.previewImg);
            }
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.author-dialog {
  /deep/.el-dialog__header {
    text-align: center;
    .el-dialog__title {
      color: #eb7269;
      font-family: GravityBold;
    }
  }
}
</style>
