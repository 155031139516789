<template>
  <div class="quill-editor">
    <div id="toolbar" class="toolbar">
      <el-card v-if="logoImg" class="box-card">
        <div class="section-logo">
          <img :src="logoImg" />
        </div>
      </el-card>

      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>CHARACTER</span>
        </div>
        <!-- <el-row class="border-bottom">
          <div class="iconfont icon-font-colors icon-font"></div>
          <select class="ql-font">
            <option
              v-for="item in fontFamily"
              :key="item"
              :label="item"
              :value="item"
            ></option>
          </select>
        </el-row> -->
        <el-row class="border-bottom">
          <img src="../assets/images/icon-size.png" alt="" class="icon-size" />
          <select class="ql-size">
            <option
              v-for="item in fontSize"
              :key="item"
              :label="item"
              :value="item"
            ></option>
          </select>
        </el-row>

        <el-row class="font-extra">
          <button class="ql-bold"></button>
          <button class="ql-italic"></button>
          <select class="ql-color"></select>
          <button class="ql-underline"></button>
          <select class="ql-background"></select>
          <button class="ql-link"></button>
          <button class="ql-header" value="1"></button>
          <button class="ql-header" value="2"></button>
        </el-row>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>PARAGRAPH</span>
        </div>
        <div class="el-row box-card-con">
          <div class="el-col">
            <img
              src="../assets/images/icon-align-left.png"
              alt=""
              class="ql-align-left iconfont-button img-icon icon-align"
              @click="format('align-left')"
            />
            <img
              src="../assets/images/icon-align-center.png"
              alt=""
              class="ql-align-center iconfont-button img-icon icon-align"
              @click="format('align-center')"
            />
            <img
              src="../assets/images/icon-align-right.png"
              alt=""
              class="ql-align-right iconfont-button img-icon icon-align"
              @click="format('align-right')"
            />
          </div>
          <div class="el-col align-extra">
            <img
              src="../assets/images/icon-align.png"
              alt=""
              class="ql-align-justify iconfont-button img-icon"
              @click="format('align-justify')"
            />
          </div>
        </div>
        <div class="box-card-bottom">
          <div class="ql-text-indent">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
            <button class="ql-indent" value="-1"></button>
            <button class="ql-indent" value="+1"></button>
          </div>
          <div class="ql-line-height">
            <img
              src="../assets/images/icon-line-height.png"
              alt=""
              class="img-icon"
            />
            <select class="ql-lineheight">
              <option
                v-for="item in lineHeight"
                :key="item"
                :label="item"
                :value="item"
              ></option>
            </select>
          </div>
        </div>
      </el-card>
      <!-- <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>ALIGN</span>
        </div>
        <el-row>
          <span class="des">Align objects</span>
        </el-row>
        <el-row>
          <el-col :span="4" style="text-align: center">
            <button
              class="iconfont icon-ic24-horizontal-align-left iconfont-button align-left"
              @click="format('align-left')"
            ></button>
          </el-col>
          <el-col :span="4" style="text-align: center">
            <button
              class="iconfont icon-format-horizontal-align-center iconfont-button align-center"
              @click="format('align-center')"
            ></button>
          </el-col>
          <el-col :span="4" style="text-align: center">
            <button
              class="iconfont icon-format-horizontal-align-right iconfont-button align-right"
              @click="format('align-right')"
            ></button>
          </el-col>
          <el-col :span="4" style="text-align: center">
            <button
              class="iconfont icon-vertical-align-top iconfont-button ql-vertical-top"
            ></button>
          </el-col>
          <el-col :span="4" style="text-align: center">
            <button
              class="iconfont icon-ic24-vertical-align-center iconfont-button"
              @click="format('vertical-middle')"
            ></button>
          </el-col>
          <el-col :span="4" style="text-align: center">
            <button
              class="iconfont icon-vertical-align-botto ql-"
              @click="format('vertical-bottom')"
            ></button>
          </el-col>
        </el-row>
      </el-card> -->
    </div>
    <div class="editor">
      <div>
        <el-input
          v-model="title"
          type="textarea"
          placeholder="Please Input the Title"
          :autosize="{ minRows: 1 }"
          class="editor-title"
        ></el-input>
        <div class="author" @click="handleInvite">
          Co-authored by:
          <span
            class="author-name"
            v-for="(item, index) in authorList"
            :key="index"
            >{{ item.student.student_givenName_pinyin }}
            {{ item.student.student_lastName_pinyin }}
          </span>
        </div>
        <div class="desc" :style="{ color: headerColor }">
          *Global Youth Pulses (GYP) requires collaborative article writing.
          Each article should be co-authored by a team of 2 to 4 collaborators.
          Please click the Author area above to invite a collaborator.
        </div>
      </div>

      <div id="editor" class="editor-content" ref="editor"></div>
    </div>
    <div class="extended">
      <el-card class="box-card">
        <div slot="header" class="clearfix">COVER PHOTO</div>
        <el-upload
          class="avatar-uploader"
          action="https://upload.qiniup.com"
          :data="qiniuData"
          :show-file-list="false"
          :on-success="handleSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <el-button
            type="primary"
            v-else
            class="upload-btn"
            :style="{ background: headerColor, borderColor: headerColor }"
            >Browse Files</el-button
          >
          <div slot="tip" class="el-upload__tip">JPG,PNG,GIF up to 8MB</div>
        </el-upload>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="clearfix">TAGS</div>
        <vue-tags-input
          style="font-size: 16px"
          v-model="tag"
          :tags="tags"
          :disabled="isAdmin"
          :tag-style="{ backgroundColor: headerColor }"
          @tags-changed="(newTags) => (tags = newTags)"
        />
      </el-card>
      <!-- <el-card class="box-card">
        <div slot="header" class="clearfix">CATEGORY</div>
        <el-select v-model="unitedValue" placeholder="United States">
          <el-option
            v-for="item in unitedStates"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-card> -->
      <div>
        <el-button
          class="save-button"
          :style="{ borderColor: headerColor }"
          @click="saveHanlder"
          >Save Draft</el-button
        >
      </div>
      <div>
        <el-button
          class="save-button"
          :style="{ borderColor: headerColor }"
          @click="saveHanlderReturn"
          >Save and Return</el-button
        >
      </div>
      <div>
        <el-button
          class="save-button"
          :style="{ borderColor: headerColor }"
          @click="saveHanlderSubmit"
          >Submit</el-button
        >
      </div>
    </div>
    <support-modal
      v-model="supportVisible"
      :authorList="authorList"
      :id="article_id"
      :agreeData="agreeData"
      @submit="submit"
    ></support-modal>
    <unsupport-modal v-model="unsupportVisible"></unsupport-modal>
    <author-manage
      v-model="authorVisible"
      :list="authorList"
      :id="article_id"
      @refreshMember="getArticleMembers"
    ></author-manage>

    <el-dialog
      title="Co-author Required"
      :visible.sync="requireCoDialog"
      width="60%"
    >
      <div class="label">
        GYP requires collaborative article writing. Each article should be
        co-authored by a minimum of two individuals. Please invite at least one
        co-author for this article.
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleConfirm">Confirm</el-button>
        <el-button type="primary" @click="handleInviteNow"
          >Invite Now</el-button
        >
      </span>
    </el-dialog>

    <!-- <Cropper v-model="cropperDialog" :cropperImg="imageUrl" /> -->
  </div>
</template>

<script>
// quill
import Quill from "quill";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.core.css";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";

// 功能性
import {
  updatArticle,
  getQiniuToken,
  checkSubscriptionStatus,
  submitReview,
  getArticleMembers,
} from "../api/index";
import { getUserId } from "../utils/store";
import { mapActions, mapState } from "vuex";
import { sectionList } from "../utils/commonList";

// 组件引用
import VueTagsInput from "@johmun/vue-tags-input";
import supportModal from "./supportModal.vue";
import unsupportModal from "./unsupportModal.vue";
import authorManage from "./authorManage.vue";
import Cropper from "./cropper.vue";
// ******************************quill ************************************************
let fontFamily = ["宋体", "黑体", "微软雅黑", "楷体", "仿宋", "Arial", "苹方"];
Quill.imports["attributors/style/font"].whitelist = fontFamily;
Quill.register(Quill.imports["attributors/style/font"]);

let fontSize = ["10px", "12px", "14px", "16px", "20px", "24px", "36px"];
Quill.imports["attributors/style/size"].whitelist = fontSize;
Quill.register(Quill.imports["attributors/style/size"]);

// 设置行高的配置======start
import { lineHeightStyle } from "@/utils/lineHeight";
Quill.register({ "formats/lineHeight": lineHeightStyle }, true);
// 设置行高的配置======end

// 设置align
let align = ["left", "center", "right", "justify"];
Quill.import("attributors/style/align").whitelist = align;
Quill.register(Quill.imports["attributors/style/align"]);
// ******************************quill ************************************************
export default {
  components: {
    VueTagsInput,
    supportModal,
    unsupportModal,
    authorManage,
    Cropper,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      editData: {},
      title: "",
      imageUrl: "",
      fontFamily: ["宋体", "黑体", "微软雅黑", "楷体", "仿宋", "Arial", "苹方"],
      fontSize: ["10px", "12px", "14px", "16px", "20px", "24px", "36px"],
      textIndent: ["1em", "2em", "3em", "4em", "5em", "6em", "7em", "8em"],
      lineHeight: ["1", "1.5", "1.75", "2", "3", "4", "5"],
      unitedStates: [
        "Campus Pulses",
        "Next Wave Business Review",
        "Literature and Art Review",
        "Comparative Thinking",
        "Intellect in Words",
        "Apex Stage",
        "History Perspectives",
        "Professor's Academic Challenge",
      ],
      font: "",
      size: "",
      tag: "",
      tags: [],
      rangeStart: 0,
      rangeLength: 0,
      formatObj: {},
      isAdmin: false,
      unitedValue: "",
      qiniuData: {
        token: "",
        key: "",
      },
      sectionList: sectionList,
      logoImg: "",
      setionType: null,
      supportVisible: false, // 支持
      unsupportVisible: false, // 不支持在这个版面发文章
      article_id: null,
      authorVisible: false,
      authorList: [],
      requireCoDialog: false,
      agreeData: {},
      cropperDialog: false,
    };
  },
  computed: {
    ...mapState(["headerColor"]),
  },
  watch: {
    data(val) {
      console.log("=====>watch", val);
      if (!val) return;
      this.quill.clipboard.dangerouslyPasteHTML(val.content);
      this.editData = val;
      // const parent = document.querySelector(".ql-editor");
      // const title = document.createElement("p");
      // title.className = "ql-editor-title";
      // title.innerText = val.title;
      // parent.prepend(title);
      this.title = val.title;
      this.agreeData = val.agrees;
      this.imageUrl = val.cover_address;
      this.tags = [];
      if (val.tags && val.tags.length > 0) {
        let temp_tags = val.tags;
        for (var i = 0; i < temp_tags.length; i++) {
          var tag = {
            text: temp_tags[i],
            style: { backgroundColor: headerColor, color: "#000000" },
          };
          this.tags.push(tag);
        }
      }
    },
    imageUrl: {
      deep: true,
      handler(val) {
        this.imageUrl = val;
      },
    },
  },
  async mounted() {
    if (this.$route.query.setionType) {
      const _sectionType = Number(this.$route.query.setionType);
      const _list = this.sectionList.filter((i) => i.value === _sectionType)[0];
      this.setionType = _sectionType;
      this.logoImg = _list.img;
      this.updateHeaderColor(_list.color);
    }
    if (this.$route.query.id) {
      this.article_id = this.$route.query.id;
    }
    this.initEditor();
    // await this.saveHanlder(false);
    this.getArticleMembers();
  },
  methods: {
    ...mapActions(["updateHeaderColor"]),
    initEditor() {
      this.quill = new Quill("#editor", {
        theme: "snow",
        modules: {
          toolbar: {
            container: "#toolbar",
            handlers: {
              "vertical-top": this.verticalAlign,
              "text-indent": function () {},
              lineheight: function (value) {
                if (value) {
                  this.quill.format("lineHeight", value);
                } else {
                  console.log(value);
                }
              },
            },
          },
        },
      });

      let that = this;
      that.quill.on("editor-change", function (eventName, ...args) {
        if (eventName === "text-change") {
          const Delta = that.quill.getContents();
          let converter = new QuillDeltaToHtmlConverter(Delta.ops, {});
          that.editData.content = converter.convert();
          // const title = Delta.ops[0].insert.replace(/(\t|\r|\n)/, "");
          // that.editData.title = title.replace(/<[^>]*>/, "");
        } else if (eventName === "selection-change") {
          // args[0] will be old range
          if (args.length === 0 || !args[0]) return;
          const idx = args[0].index;
          const len = args[0].length;
          that.rangeStart = idx;
          that.rangeLength = len;
          that.formatObj = that.quill.getFormat(idx, len);
        }
      });
    },
    goBack() {
      if (this.$route.query.action === "draft") {
        this.$router.replace({ path: "/draft" });
      } else {
        this.$router.go(-1);
      }
    },
    async saveHanlder(isMsg = true) {
      let tempTags = [];
      if (this.tags.length > 0) {
        let length = this.tags.length;
        for (var i = 0; i < length; i++) {
          var tag = this.tags[i].text;
          tempTags.push(tag);
        }
      }
      console.log(tempTags);
      let params = {
        user_id: getUserId(),
        id: this.article_id || "",
        title: this.title,
        tags: tempTags,
        content: this.editData.content || "",
        type: this.setionType,
        cover_address: this.imageUrl,
      };
      const res = await updatArticle(params);
      const { data, code, msg } = res.data;
      console.log("=====>save", res);
      if (code === 0) {
        isMsg && this.$message.success("Article saved successfully.");
        this.article_id = data._id.$id;
      } else {
        this.$message.error(msg);
      }
    },
    saveHanlderReturn() {
      this.saveHanlder();
      this.goBack();
    },
    async checkSubscriptionStatus() {
      const _type = this.setionType === 1 ? "campus_pulses" : "other";
      const res = await checkSubscriptionStatus(getUserId(), _type);
      if (res.data) {
        if (this.authorList.length >= 2) {
          this.supportVisible = true;
        } else {
          this.requireCoDialog = true;
        }
      } else {
        this.unsupportVisible = true;
      }
    },
    async submit() {
      await this.saveHanlder(false);
      const res = await submitReview(this.article_id);
      const { code, msg } = res.data;
      if (code == 0) {
        this.supportVisible = false;
        this.$message.success("Article submitted successfully.");
        setTimeout(() => {
          this.$router.replace({
            path: "article",
          });
        }, 300);
      } else {
        this.$message.error(msg);
      }
    },
    saveHanlderSubmit() {
      this.checkSubscriptionStatus();
    },
    handleSuccess(data) {
      this.imageUrl = "http://mainbucket.reachable-edu.com/" + data.key;
    },
    beforeAvatarUpload(file) {
      this.qiniuData.key = file.name;
      const isJPG = [
        "image/jpg",
        "image/jpeg",
        "image/png",
        "image/gif",
      ].includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 8;

      if (!isJPG) {
        this.$message.error(
          "Only images in JPG/PNG/GIF formats can be uploaded."
        );
        return;
      }
      if (!isLt2M) {
        this.$message.error("The image size must not exceed 8MB.");
        return;
      }

      return new Promise((resolve, reject) => {
        getQiniuToken()
          .then((res) => {
            if (res.data.code === 0) {
              this.qiniuData.token = res.data.data;
              // this.cropperDialog = true;
              resolve(true);
            } else {
              reject(false);
            }
          })
          .catch((err) => {
            console.log(err);
            reject(false);
          });
      });
    },
    format(action) {
      console.log(this.rangeStart);
      this.quill.setSelection(this.rangeStart, this.rangeLength);
      const Delta = this.quill.getContents(this.rangeStart, this.rangeLength);
      switch (action) {
        case "align-left":
          this.quill.format("align", "left");
          break;
        case "align-center":
          this.quill.format("align", "center");
          break;
        case "align-right":
          this.quill.format("align", "right");
          break;
        case "align-justify":
          this.quill.format("align", "justify");
          break;
        case "vertical-top":
          this.quill.format("vertical", "top");
          break;
        case "vertical-middle":
          this.quill.format("vertical", "middle");
          break;
        case "vertical-bottom":
          this.quill.format("vertical", "bottom");
          break;
      }
    },
    getFormatOfSelection(idx, len) {
      const formatObj = this.quill.getFormat(idx, len);
      formatObj;
    },
    activeStyleButton() {},
    verticalAlign(action) {
      // const delta = this.quill.getFormat(this.rangeStart,this.rangeLength);
      // if(delta.vertical)
      const top = {
        verticalAlign: "top",
      };
      const middle = {
        verticalAlign: "middle",
      };
      const bottom = {
        verticalAlign: "bottom",
      };
      this.quill.formatText(this.rangeStart, this.rangeLength, top, true);
    },
    async getArticleMembers() {
      // await this.saveHanlder(false);
      const res = await getArticleMembers(this.article_id);
      const { code, data } = res.data || {};
      if (code === 0) {
        this.authorList = data;
      }
    },
    handleInvite() {
      this.authorVisible = true;
    },
    handleConfirm() {
      this.requireCoDialog = false;
    },
    handleInviteNow() {
      this.requireCoDialog = false;
      this.authorVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.quill-editor {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 90px);
  //padding: 30px 20px;
  background-color: #f2f4f9;
  .toolbar {
    margin-right: 20px;
    border: none;
    padding: 0;
    width: 260px;
    .box-card {
      margin-bottom: 12px;
      overflow: inherit;
      box-shadow: none;
      border-radius: 8px;
      &-con {
        display: flex;
        height: 40px;
        align-items: center;
      }
      &-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .clearfix {
        font-size: 12px;
      }
    }
  }
  .editor {
    flex: 1;
    margin-right: 20px;
    background-color: #fff;
    padding: 30px;
    overflow-y: auto;
    border: none;
    border-radius: 8px;
    word-break: break-word;
    &-content {
      flex: 3;
      margin-right: 20px;
      background-color: #fff;
      overflow-y: auto;
      border: none;
      border-radius: 8px;
    }
    &-title {
      font-size: 28px;
      line-height: 32px;
      border-bottom: 1px solid #eff3f6;
      padding-bottom: 24px;
      /deep/.el-textarea__inner {
        border: none;
        color: #000000;
        font-family: GravityNormal;
      }
    }
    /deep/.ql-editor-title {
      font-size: 20px;
      font-weight: bold;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px dotted #ccc;
      background: pink;
    }
    /deep/.ql-editor-content {
      font-size: 20px;
      font-weight: bold;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px dotted #ccc;
      background: purple;
    }
    .author {
      font-size: 12px;
      margin: 12px 15px;
    }
    .desc {
      font-size: 12px;
      font-style: italic;
      line-height: 22px;
      margin: 0 15px;
    }
  }
  .extended {
    width: 260px;
    .box-card {
      margin-bottom: 12px;
      overflow: inherit;
      box-shadow: none;
      border-radius: 8px;
      .clearfix {
        font-size: 12px;
      }
    }
    .tags {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      .tag {
        padding: 5px 20px;
        margin-right: 5px;
        border-radius: 12px;
        background-color: #ccc;
        font-size: 12px;
      }
    }
    .save-button {
      width: 100%;
      margin-top: 10px;
      color: #181818;
      border: 1px solid #181818;
      font-size: 14px;
      background-color: #f2f4f9;
    }
  }
}
.author-name {
  &::after {
    content: "|";
    display: inline-block;
    margin-right: 4px;
  }
  &:last-child {
    &::after {
      content: "";
    }
  }
}

.avatar-uploader {
  height: 200px;
  width: 98%;
  margin: 0 auto;
  background-color: #f1f3f5;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  /deep/.el-upload.el-upload--text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /deep/.el-upload__tip {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    color: #173e67;
    opacity: 0.4;
  }
  .upload-btn {
    width: 120px;
    height: 40px;
    border-radius: 5px;
    background-color: var(--c-primary);
    border-color: var(--c-primary);
  }
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
  background-size: cover;
}

.font-icon {
  height: 20px;
  width: 20px;
  display: inline-block;
}

.border-bottom {
  display: flex;
  align-items: center;
  .icon-font {
    margin-right: 10px;
  }
}
.icon-size {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.img-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
}

.icon-align {
  margin-right: 20px;
}
.align-extra {
  position: absolute;
  right: 0;
  &::before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 40px;
    background: #eff3f6;
    position: absolute;
    top: -6px;
    margin-left: -15px;
  }
}

.des {
  font-size: 12px;
  color: #ccc;
}

.font-extra {
  display: flex;
}
/deep/.el-row {
  padding-bottom: 10px;
}
/deep/.el-card__body {
  padding: 16px;
}
/deep/.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  float: none;
}

/deep/.ql-picker.ql-font,
.ql-size {
  width: 100%;
  border: 1px solid #ccc;
  height: 30px;
  padding: 5px 0;
}

/deep/.ql-color .ql-picker .ql-color-picker {
  float: none;
}
//字体图标样式
/deep/.iconfont {
  font-size: 18px;
}

//字体样式
.ql-snow .ql-picker.ql-font {
  //width: 120px; // 菜单栏占比宽度
}
/deep/.ql-snow .ql-picker .ql-picker-label {
  display: flex;
  align-items: center;
  border: none;
}
/deep/.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="宋体"]::before,
/deep/.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="宋体"]::before {
  content: "宋体";
  font-family: "SimSun";
}
/deep/.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="黑体"]::before,
/deep/.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="黑体"]::before {
  content: "黑体";
  font-family: "SimHei";
}
/deep/.ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="微软雅黑"]::before,
/deep/.ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="微软雅黑"]::before {
  content: "微软雅黑";
  font-family: "Microsoft YaHei";
}
/deep/.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="楷体"]::before,
/deep/.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="楷体"]::before {
  content: "楷体";
  font-family: "KaiTi";
}
/deep/.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="仿宋"]::before,
/deep/.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="仿宋"]::before {
  content: "仿宋";
  font-family: "FangSong";
}
/deep/.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before,
/deep/.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before {
  content: "Arial";
  font-family: "Arial";
}
/deep/.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="苹方"]::before,
/deep/.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="苹方"]::before {
  content: "苹方";
  font-family: "PingFang SC";
}

//字体大小
.ql-snow .ql-picker.ql-size {
  width: 120px; // 菜单栏占比宽度
}
/deep/.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="10px"]::before,
/deep/.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10px";
}
/deep/.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
/deep/.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12px";
}
/deep/.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
/deep/.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14px";
}
/deep/.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
/deep/.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16px";
}
/deep/.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
/deep/.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20px";
}
/deep/.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
/deep/.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24px";
}
/deep/.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="36px"]::before,
/deep/.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
  content: "36px";
}

/deep/.ql-align-center {
  text-align: center;
}

/deep/.ql-align-right {
  text-align: right;
}

/deep/.ql-align-left {
  text-align: left;
}
.ql-snow .ql-picker.ql-lineheight {
  width: 70px; // 菜单栏占比宽度
}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label::before {
  content: "行高";
}
.ql-snow
  .ql-picker.ql-lineheight
  .ql-picker-item[data-value="initial"]::before {
  content: "默认";
}
/deep/.ql-snow
  .ql-picker.ql-lineheight
  .ql-picker-label[data-value="1"]::before,
/deep/.ql-snow
  .ql-picker.ql-lineheight
  .ql-picker-item[data-value="1"]::before {
  content: "1";
}
/deep/.ql-snow
  .ql-picker.ql-lineheight
  .ql-picker-item[data-value="1.5"]::before {
  content: "1.5";
}
/deep/.ql-snow
  .ql-picker.ql-lineheight
  .ql-picker-item[data-value="1.75"]::before {
  content: "1.75";
}
/deep/.ql-snow
  .ql-picker.ql-lineheight
  .ql-picker-item[data-value="2"]::before {
  content: "2";
}
/deep/.ql-snow
  .ql-picker.ql-lineheight
  .ql-picker-item[data-value="3"]::before {
  content: "3";
}
/deep/.ql-snow
  .ql-picker.ql-lineheight
  .ql-picker-item[data-value="4"]::before {
  content: "4";
}
/deep/.ql-snow
  .ql-picker.ql-lineheight
  .ql-picker-item[data-value="5"]::before {
  content: "5";
}

/deep/.ql-snow .ql-picker {
  float: none;
}
.ql-lineheight,
.ql-textindent {
  border: 1px solid #ccc;
  height: 30px;
  padding: 5px 0;
  margin-left: 10px;
}
// 分割线
.separator {
  border-top: 1px solid #eee; /* 定义线条的样式 */
  margin: 20px 0; /* 定义线条的上下边距 */
}
</style>
